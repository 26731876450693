import React from "react";

import ReferenzAnimation from "../FrizzComponent/ReferenzAnimation";

class ReferenzIntro extends React.Component {
  constructor() {
    super();
    this.state = {
      width: window.innerWidth
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
    const { width } = this.state;
    const isMobile = width <= 812;

    if (isMobile) {
      return (
        <React.Fragment>
          <section id="section-frizz">
            <div className="section__content columns container--padding">
              <div className="teaser__text column--6">
                <div className="teaser__text-content">
                  <p className="teaser__text-subtitle--frizz">
                  Frizz Production4Toolzz GmbH
                  </p>
                  <h3 className="teaser__text-title">Start up!</h3>
                </div>
              </div>
              <div className="section__intro-content column--6">
                <p className="teaser__text-details">
                  Schnell, wendig, spritzig und irgendwie anders als alle
                  anderen wollte Frizz sein. In der optisch häufig behäbigen
                  Maschinenbaubranche sollte das Start up sich in jeder Hinsicht
                  abheben. Mit einem typisch „Made in Germany“-klingenden Namen,
                  aber einem überraschenden Markenauftritt ging Frizz an den
                  Start – mit Precision Toolzz!
                </p>
              </div>
            </div>
          </section>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <section id="section-frizz" className="section--intro">
            <div className="section__content columns container--padding">
              <div className="column column--4"></div>
              <div className="column column--4" style={{ marginTop: "-8%" }}>
                <ReferenzAnimation />
              </div>
              <div className="column column--4"></div>
            </div>

            <div className="section__content columns container">
              <div className="teaser__text column--6">
                <div className="teaser__text-content">
                  <p className="teaser__text-subtitle--frizz">
                  Frizz Production4Toolzz GmbH
                  </p>
                  <h3 className="teaser__text-title">Start up!</h3>
                </div>
              </div>
              <div className="section__intro-content column--6">
                <p className="teaser__text-details margin">
                  Schnell, wendig, spritzig und irgendwie anders als alle
                  anderen wollte Frizz sein. In der optisch häufig behäbigen
                  Maschinenbaubranche sollte das Start up sich in jeder Hinsicht
                  abheben. Mit einem typisch „Made in Germany“-klingenden Namen,
                  aber einem überraschenden Markenauftritt ging Frizz an den
                  Start – mit Precision Toolzz!
                </p>
              </div>
            </div>
          </section>
        </React.Fragment>
      );
    }
  }
}
export default ReferenzIntro;
