import React from "react";

import Frizz_Referenzen_Mood_neu from "../../../assets/images/Referenzen/Frizz/Kampagne/Frizz_Referenzen_Mood_neu.jpg";
import Frizz_Geschaeftsausstattung_Briefbogen from "../../../assets/images/Referenzen/Frizz/Kampagne/Frizz_Geschaeftsausstattung_Briefbogen.jpg";
import Frizz_Geschaeftsausstattung_VKundLogo from "../../../assets/images/Referenzen/Frizz/Kampagne/Frizz_Geschaeftsausstattung_VKundLogo.jpg";

import Frizz_Referenzen_Mood_1260px_breit from "../../../assets/images/Referenzen/Frizz/Kampagne/Frizz_Referenzen_Mood_1260px_breit.jpg";
import Frizz_Titel_Brosch_quadratisch_neu from "../../../assets/images/Referenzen/Frizz/Kampagne/Frizz_Titel_Brosch_quadratisch_neu.jpg";
import Frizz_Doppels_Brosch_quadratisch_2 from "../../../assets/images/Referenzen/Frizz/Kampagne/Frizz_Doppels_Brosch_quadratisch_2.jpg";
import Frizz_Doppels_Brosch_quadratisch_1 from "../../../assets/images/Referenzen/Frizz/Kampagne/Frizz_Doppels_Brosch_quadratisch_1.jpg";
import Frizz_Doppels_Brosch_quadratisch_3 from "../../../assets/images/Referenzen/Frizz/Kampagne/Frizz_Doppels_Brosch_quadratisch_3.jpg";

import Frizz_Keyvisuals_hoch_2 from "../../../assets/images/Referenzen/Frizz/Kampagne/Frizz_Keyvisuals_hoch_2.jpg";
import Frizz_Keyvisuals_hoch_1 from "../../../assets/images/Referenzen/Frizz/Kampagne/Frizz_Keyvisuals_hoch_1.jpg";
import Frizz_Keyvisuals_hoch_3 from "../../../assets/images/Referenzen/Frizz/Kampagne/Frizz_Keyvisuals_hoch_3.jpg";

import Frizz_Doppels_Katalog_A4_hoch_1_615px_breit from "../../../assets/images/Referenzen/Frizz/Kampagne/Frizz_Doppels_Katalog_A4_hoch_1_615px_breit.jpg";
import Frizz_Titel_Katalog_A4_hoch_615px_breit from "../../../assets/images/Referenzen/Frizz/Kampagne/Frizz_Titel_Katalog_A4_hoch_615px_breit_neu.jpg";
import Frizz_Doppels_Katalog_A4_hoch_2_615px_breit from "../../../assets/images/Referenzen/Frizz/Kampagne/Frizz_Doppels_Katalog_A4_hoch_2_615px_breit.jpg";

import Frizz_Werbemittel_Dosen_615px_breit from "../../../assets/images/Referenzen/Frizz/Kampagne/Frizz_Werbemittel_Dosen_615px_breit_neu.jpg";
import Frizz_Werbemittel_Streichhoelzer_615px_breit from "../../../assets/images/Referenzen/Frizz/Kampagne/Frizz_Werbemittel_Streichhoelzer_615px_breit_neu.jpg";

export default class ReferenzContent extends React.Component {
  render() {
    return (
      <React.Fragment>
        <section
          id="section-frizz-1"
          className="section--content section--padding"
        >
          <div className="section__content columns container-section">
            <div className="column column--6">
              <div className="section__image-content-wrapper">
                <img
                  ref="image"
                  src={Frizz_Referenzen_Mood_neu}
                  className="teaser__image-left"
                  alt="Frizz Corporate Design"
                />
              </div>
            </div>

            <div className="column column--6">
              <div className="teaser__text padding">
                <div className="teaser__text-content">
                  <p className="teaser__text-subtitle--frizz">
                    Corporate Design
                  </p>
                  <h3 className="teaser__text-title">Unkonventionell</h3>
                  <div>
                    <p className="teaser__text-details">
                      Typisch deutsch im Namen, aber international verständlich
                      haben wir mit der Marke Frizz ein Branding kreiert, das im
                      Maschinenbau durch Form und durch Farbe auffällt.
                      Dynamisch, schnell und wendig bohrt sich das Logo optisch
                      in die Wahrnehmung und bleibt bis aufs i-Tüpfelchen im
                      Gedächtnis. Bewusst spielerisch leitet sich davon das
                      Corporate Design ab und steckt den Rahmen für vielfältige
                      Gestaltungsmöglichkeiten von der Visitenkarte über den
                      Messestand bis zum Give-away. Einfach cooler Look in allen
                      Medien.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--6">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <img
                    ref="image"
                    src={Frizz_Geschaeftsausstattung_Briefbogen}
                    style={{ width: "100%", height: "100%" }}
                    alt="Frizz Corporate Design"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--6">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <img
                    ref="image"
                    src={Frizz_Geschaeftsausstattung_VKundLogo}
                    style={{ width: "100%", height: "100%" }}
                    alt="Frizz Corporate Design"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          id="section-frizz-2"
          className="section--content section--padding"
        >
          <div className="section__content columns infinite__container">
            <div className="column column--6">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <img
                    ref="image"
                    src={Frizz_Referenzen_Mood_1260px_breit}
                    className="teaser__image-right"
                    alt="Frizz 3D-Visualisierung"
                  />
                </div>
              </div>
            </div>
            <div className="column column--6">
              <div className="teaser__text--intro padding">
                <div className="teaser__text-content">
                  <p className="teaser__text-subtitle--frizz">Key-Visual</p>
                  <h3 className="teaser__text-title">3D visualisiert!</h3>
                  <div>
                    <p className="teaser__text-details">
                      Während Produktmarke mit samt Katalogen, Website und
                      Messeauftritt bereits in der Entwicklung waren, ließ das
                      fertige Produkt noch auf sich warten. Kein Problem für DAS
                      ACHTE GEBOT und so wurden die Produkte für Inszenierung
                      einfach cool gerendert und schon mal optisch an den Start
                      gebracht, noch bevor die Fertigung lief.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding-big">
            <div className="column column--6">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <h5 className="teaser__text-title">Imagebroschüre</h5>
                  <p className="teaser__text-details">
                    Frische Limette statt langatmiger Firmengeschichte. Wenig
                    Worte, große Bilder – auch das schafft starke Wirkung.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--6">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <img
                    ref="image"
                    src={Frizz_Titel_Brosch_quadratisch_neu}
                    style={{ width: "100%", height: "100%" }}
                    alt="Frizz Broschuere"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--6">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <img
                    ref="image"
                    src={Frizz_Doppels_Brosch_quadratisch_1}
                    style={{ width: "100%", height: "100%" }}
                    alt="Frizz Broschuere"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--6">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <img
                    ref="image"
                    src={Frizz_Doppels_Brosch_quadratisch_2}
                    style={{ width: "100%", height: "100%" }}
                    alt="Frizz Broschuere"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--6">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <img
                    ref="image"
                    src={Frizz_Doppels_Brosch_quadratisch_3}
                    style={{ width: "100%", height: "100%" }}
                    alt="Frizz Broschuere"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding-big">
            <div className="column column--6">
              <div className="teaser__text padding-middle">
                <div className="teaser__text-content">
                  <div className="section__image-content-wrapper">
                    <h5 className="teaser__text-title">Inszenierung</h5>
                    <p className="teaser__text-details">
                      Unterschiedliche Vollhartmetall-Bohrer einheitlich
                      präsentiert. Aus den Grundgeometrien des Logos entsteht
                      ein Glow, der die Produkte dynamisiert und eine grafische
                      Konstante bietet.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--6">
              <div className="teaser__text padding-middle">
                <div className="teaser__text-content">
                  <div className="section__image-content-wrapper">
                    <img
                      ref="image"
                      src={Frizz_Keyvisuals_hoch_1}
                      style={{ width: "100%", height: "100%" }}
                      alt="Frizz Key-Visual"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--6">
              <div className="teaser__text padding-middle">
                <div className="teaser__text-content">
                  <div className="section__image-content-wrapper">
                    <img
                      ref="image"
                      src={Frizz_Keyvisuals_hoch_2}
                      style={{ width: "100%", height: "100%" }}
                      alt="Frizz Key-Visual"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--6">
              <div className="teaser__text padding-middle">
                <div className="teaser__text-content">
                  <div className="section__image-content-wrapper">
                    <img
                      ref="image"
                      src={Frizz_Keyvisuals_hoch_3}
                      style={{ width: "100%", height: "100%" }}
                      alt="Frizz Key-Visual"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding-big">
            <div className="column column--6">
              <div className="teaser__text padding-middle">
                <div className="teaser__text-content">
                  <div className="section__image-content-wrapper">
                    <h5 className="teaser__text-title">Katalog</h5>
                    <p className="teaser__text-details">
                      Von der Datenbank in den fertigen Katalog. Komplett
                      digital und in 3D – in Windeseile und püntklich zur
                      Leitmesse realisiert.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--6">
              <div className="teaser__text padding-middle">
                <div className="teaser__text-content">
                  <div className="section__image-content-wrapper">
                    <img
                      ref="image"
                      src={Frizz_Titel_Katalog_A4_hoch_615px_breit}
                      style={{ width: "100%", height: "100%" }}
                      alt="Frizz Katalog"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--6">
              <div className="teaser__text padding-middle">
                <div className="teaser__text-content">
                  <div className="section__image-content-wrapper">
                    <img
                      ref="image"
                      src={Frizz_Doppels_Katalog_A4_hoch_1_615px_breit}
                      style={{ width: "100%", height: "100%" }}
                      alt="Frizz Katalog"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--6">
              <div className="teaser__text padding-middle">
                <div className="teaser__text-content">
                  <div className="section__image-content-wrapper">
                    <img
                      ref="image"
                      src={Frizz_Doppels_Katalog_A4_hoch_2_615px_breit}
                      style={{ width: "100%", height: "100%" }}
                      alt="Frizz Katalog"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding-big">
            <div className="column column--6-bottom">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <h5 className="teaser__text-title">Werbemittel</h5>
                  <p className="teaser__text-details">
                    Mit der neuen Marke lässt sich vielfältig spielen. Der coole
                    Energy-Drink passt optisch und inhaltlich genauso, wie die
                    filigranen Zündhölzer mit Köpfchen.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--6-bottom">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <img
                    ref="image"
                    src={Frizz_Werbemittel_Dosen_615px_breit}
                    style={{ width: "100%", height: "100%" }}
                    alt="Frizz Dosen"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="section__content columns container--padding">
            <div className="column column--6-bottom">
              <div className="section__image-content">
                <div className="section__image-content-wrapper">
                  <img
                    ref="image"
                    src={Frizz_Werbemittel_Streichhoelzer_615px_breit}
                    style={{ width: "100%", height: "100%" }}
                    alt="Frizz Streichhoelzer"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
