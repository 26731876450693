import React from "react";

import DynamicScrollToTop from "../components/ScrollToTopComponent/DynamicScrollToTop";
import Random from "../components/RandomComponent/Random";
import Header from "../components/HeaderComponent/Header";
import ReferenzFlexDesktop from "../components/ReferenzComponent/FlexComponent/ReferenzImageDesktop";
import ReferenzGeberitDesktop from "../components/ReferenzComponent/GeberitComponent/ReferenzImageDesktop";
import ReferenzDuerrDesktop from "../components/ReferenzComponent/DuerrComponent/ReferenzImageDesktop";
import ReferenzEWGDesktop from "../components/ReferenzComponent/EWGComponent/ReferenzImageDesktop";
import ReferenzFelsomatDesktop from "../components/ReferenzComponent/FelsomatComponent/ReferenzImageDesktop";
import ReferenzJanusDesktop from "../components/ReferenzComponent/JanusComponent/ReferenzImageDesktop";
import ReferenzMevaDesktop from "../components/ReferenzComponent/MevaComponent/ReferenzImageDesktop";
import ReferenzKlingbeilDesktop from "../components/ReferenzComponent/KlingbeilComponent/ReferenzImageDesktop";
import ReferenzSolidianDesktop from "../components/ReferenzComponent/SolidianComponent/ReferenzImageDesktop";
import ReferenzFrizzDesktop from "../components/ReferenzComponent/FrizzComponent/ReferenzImageDesktop";
import ReferenzSikaDesktop from "../components/ReferenzComponent/SikaComponent/ReferenzImageDesktop";
import ReferenzFLVDesktop from "../components/ReferenzComponent/FLVComponent/ReferenzImageDesktop";
import ReferenzSprayMaxDesktop from "../components/ReferenzComponent/SprayMaxComponent/ReferenzImageDesktop";
import ReferenzBeltonDesktop from "../components/ReferenzComponent/BeltonComponent/ReferenzImageDesktop";
import ReferenzKwasnyDesktop from "../components/ReferenzComponent/KwasnyComponent/ReferenzImageDesktop";
import ReferenzDevylDesktop from "../components/ReferenzComponent/DevylComponent/ReferenzImageDesktop";
import Referenz3bIDODesktop from "../components/ReferenzComponent/3bIDOComponent/ReferenzImageDesktop";

import references from "../store/references";

class ReferenzenDesktop extends React.Component {
  referenzCount = 17;
  referenzIndex = 1;

  componentDidMount() {
    // console.log(references);
  }

  render() {
    return (
      <React.Fragment>
        <DynamicScrollToTop />
        <Random />
        <Header />
        <section className="section__wrapper">

          <ReferenzGeberitDesktop
            referenzNumber={this.referenzIndex++}
            referenzCount={this.referenzCount}
          />
          <ReferenzFlexDesktop
            referenzNumber={this.referenzIndex++}
            referenzCount={this.referenzCount}
          />
          <ReferenzDevylDesktop
            referenzNumber={this.referenzIndex++}
            referenzCount={this.referenzCount}
          />
          <ReferenzSikaDesktop
            referenzNumber={this.referenzIndex++}
            referenzCount={this.referenzCount}
          />
          <ReferenzBeltonDesktop
            referenzNumber={this.referenzIndex++}
            referenzCount={this.referenzCount}
          />
          <Referenz3bIDODesktop
            referenzNumber={this.referenzIndex++}
            referenzCount={this.referenzCount}
          />
          <ReferenzDuerrDesktop
            referenzNumber={this.referenzIndex++}
            referenzCount={this.referenzCount}
          />
          <ReferenzSprayMaxDesktop
            referenzNumber={this.referenzIndex++}
            referenzCount={this.referenzCount}
          />
          <ReferenzFLVDesktop
            referenzNumber={this.referenzIndex++}
            referenzCount={this.referenzCount}
          />
          <ReferenzEWGDesktop
            referenzNumber={this.referenzIndex++}
            referenzCount={this.referenzCount}
          />
          <ReferenzKwasnyDesktop
            referenzNumber={this.referenzIndex++}
            referenzCount={this.referenzCount}
          />
          <ReferenzJanusDesktop
            referenzNumber={this.referenzIndex++}
            referenzCount={this.referenzCount}
          />
          <ReferenzFelsomatDesktop
            referenzNumber={this.referenzIndex++}
            referenzCount={this.referenzCount}
          />
          <ReferenzSolidianDesktop
            referenzNumber={this.referenzIndex++}
            referenzCount={this.referenzCount}
          />
          <ReferenzMevaDesktop
            referenzNumber={this.referenzIndex++}
            referenzCount={this.referenzCount}
          />
          <ReferenzKlingbeilDesktop
            referenzNumber={this.referenzIndex++}
            referenzCount={this.referenzCount}
          />
          <ReferenzFrizzDesktop
            referenzNumber={this.referenzIndex++}
            referenzCount={this.referenzCount}
          />
        </section>
      </React.Fragment>
    );
  }
}
export default ReferenzenDesktop;
