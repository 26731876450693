import React from "react";
import Div100vh from "react-div-100vh";
import ScrollSnap from "scroll-snap";

import ScrollUp from "../components/ButtonComponent/ButtonScrollUp";
import Header from "../components/HeaderComponent/Header";
import Random from "../components/RandomComponent/Random";
import ReferenzDuerrMobile from "../components/ReferenzComponent/DuerrComponent/ReferenzImageMobile";
import ReferenzEWGMobile from "../components/ReferenzComponent/EWGComponent/ReferenzImageMobile";
import ReferenzFelsomatMobile from "../components/ReferenzComponent/FelsomatComponent/ReferenzImageMobile";
import ReferenzFlexMobile from "../components/ReferenzComponent/FlexComponent/ReferenzImageMobile";
import ReferenzFLVMobile from "../components/ReferenzComponent/FLVComponent/ReferenzImageMobile";
import ReferenzFrizzMobile from "../components/ReferenzComponent/FrizzComponent/ReferenzImageMobile";
import ReferenzGeberitMobile from "../components/ReferenzComponent/GeberitComponent/ReferenzImageMobile";
import ReferenzJanusMobile from "../components/ReferenzComponent/JanusComponent/ReferenzImageMobile";
import ReferenzKlingbeilMobile from "../components/ReferenzComponent/KlingbeilComponent/ReferenzImageMobile";
import ReferenzMevaMobile from "../components/ReferenzComponent/MevaComponent/ReferenzImageMobile";
import ReferenzSikaMobile from "../components/ReferenzComponent/SikaComponent/ReferenzImageMobile";
import ReferenzSolidianMobile from "../components/ReferenzComponent/SolidianComponent/ReferenzImageMobile";
import ReferenzSprayMaxMobile from "../components/ReferenzComponent/SprayMaxComponent/ReferenzImageMobile";
import ReferenzBeltonMobile from "../components/ReferenzComponent/BeltonComponent/ReferenzImageMobile";
import ReferenzKwasnyMobile from "../components/ReferenzComponent/KwasnyComponent/ReferenzImageMobile";
import ReferenzDevylMobile from "../components/ReferenzComponent/DevylComponent/ReferenzImageMobile";
import Referenz3bIDOlMobile from "../components/ReferenzComponent/3bIDOComponent/ReferenzImageMobile";


function callback() {}
class ReferenzenMobile extends React.Component {
  container = React.createRef();

  bindScrollSnap() {
    const element = this.container.current;
    const snapElement = new ScrollSnap(element, {
      snapDestinationY: "100vh",
      duration: 300,

      // snapDestinationY: "100%"
    });

    snapElement.bind(callback);
  }

  componentDidMount() {
    this.bindScrollSnap();
  }

  render() {
    return (
      <React.Fragment>
        <ScrollUp />
        <Random />
        <Header />
        <Div100vh>
          <div className="page__container" ref={this.container}>
            <div className="page">
              <ReferenzGeberitMobile />
            </div>
            <div className="page">
              <ReferenzFlexMobile />
            </div>
            <div className="page">
              <ReferenzDevylMobile />
            </div>
            <div className="page">
              <ReferenzSikaMobile />
            </div>
            <div className="page">
              <ReferenzBeltonMobile />
            </div>
            <div className="page">
              <Referenz3bIDOlMobile />
            </div>
            <div className="page">
              <ReferenzDuerrMobile />
            </div>
            <div className="page">
              <ReferenzSprayMaxMobile />
            </div>
            <div className="page">
              <ReferenzFLVMobile />
            </div>
            <div className="page">
              <ReferenzEWGMobile />
            </div>            
            <div className="page">
              <ReferenzKwasnyMobile />
            </div>
            <div className="page">
              <ReferenzJanusMobile />
            </div>
            <div className="page">
              <ReferenzFelsomatMobile />
            </div>
            <div className="page">
              <ReferenzSolidianMobile />
            </div>
            <div className="page">
              <ReferenzMevaMobile />
            </div>
            <div className="page">
              <ReferenzKlingbeilMobile />
            </div>
            <div className="page">
              <ReferenzFrizzMobile />
            </div>
          </div>
        </Div100vh>
      </React.Fragment>
    );
  }
}
export default ReferenzenMobile;
